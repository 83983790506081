import {
  Box,
  BoxProps,
  Chip,
  DbtDataFieldTypeIcon,
  Divider,
  Highlighter,
  IconButton,
  ListItemButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "components";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { ellipsisStyle } from "consts";
import { DbtDataField, DbtTable } from "models";
import { useState } from "react";
import { getSpacing } from "theme";
import produce from "immer";
import { DashboardPageNavSelectTableField } from "./DashboardPageNavSelectTableField";

type DashboardPageNavSelectTableProps = Omit<BoxProps, "children"> & {
  table: DbtTable;
  isTableSelected: boolean;
  selectedFields: string[] | undefined;
  setSelectedFields: (selectedFields: string[]) => void;
  searchString: string;
  hovered?: boolean;
};

export const DashboardPageNavSelectTable = ({
  table,
  isTableSelected,
  selectedFields,
  setSelectedFields,
  searchString,
  hovered,
}: DashboardPageNavSelectTableProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const tableDisplayName = table.displayName || table.name;

  const selectAllFields = (event: React.MouseEvent<HTMLLIElement>) => {
    setSelectedFields(table.dataFields?.map((dataField) => dataField.dataField) ?? []);
    setAnchorEl(null);
  };

  const clearAllFields = (event: React.MouseEvent<HTMLLIElement>) => {
    setSelectedFields([]);
    setAnchorEl(null);
  };

  let dataFields: DbtDataField[] = [];

  // if expanded, show all fields
  if (expanded) {
    dataFields = table.dataFields;
  } else if (searchString) { // if searching, show only matching fields
    dataFields = table.dataFields.filter(
      (x) =>
        x.dataField.toLowerCase().includes(searchString.toLowerCase()) ||
        x.displayName?.toLowerCase().includes(searchString.toLowerCase())
    );
  }

  return (
    <Box>
      <Chip
        sx={{ width: "100%" }}
        fullWidthLabel
        onClick={() => setExpanded(!expanded)}
        variant={isTableSelected ? "filled" : "outlined"}
        color="primary"
        label={
          <Stack
            direction="row"
            spacing="xs"
            alignItems="center"
            justifyContent="space-between"
            minHeight="20px"
            width="100%"
          >
            <KeyboardArrowUpIcon
              sx={{
                transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                transition: "all 0.2s",
              }}
            />

            <Stack {...ellipsisStyle} flexGrow={1}>
              <Highlighter searchWords={[searchString]} textToHighlight={tableDisplayName} />
            </Stack>

            {hovered && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setAnchorEl(e.currentTarget);
                }}
                size="small"
                color="inherit"
              >
                <MoreHorizIcon />
              </IconButton>
            )}
          </Stack>
        }
      />

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "customized-menu",
        }}
      >
        <MenuItem onClick={selectAllFields} disabled={selectedFields?.length === table.dataFields.length}>
          <Stack direction="row" spacing="xs">
            <Box>+ Select all</Box>
            <Typography variant="inherit" fontWeight="medium" component="span">
              {table.dataFields.length}
            </Typography>
            <Box>fields</Box>
          </Stack>
        </MenuItem>
        <MenuItem onClick={clearAllFields} disabled={!selectedFields?.length || selectedFields?.length === 0}>
          - Clear all fields
        </MenuItem>
        <Divider />
        <Box px={getSpacing("sm")}>
          <Stack direction="row" spacing="xs">
            <Typography variant="caption">Display Name:</Typography>
            <Typography variant="caption" fontWeight={"medium"}>
              {table.displayName}
            </Typography>
          </Stack>

          <Stack direction="row" spacing="xs">
            <Typography variant="caption">Table Name:</Typography>
            <Typography variant="caption" fontWeight={"medium"}>
              {table.name}
            </Typography>
          </Stack>

          <Stack direction="row" spacing="xs">
            <Typography variant="caption">Fields:</Typography>
            <Typography variant="caption" fontWeight={"medium"}>
              {table.dataFields?.length}
            </Typography>
          </Stack>
        </Box>
      </Menu>

      {dataFields.length > 0 && (
        <Box pl={getSpacing("md")} py={getSpacing("sm")}>
          <Stack spacing="sm">
            {dataFields.map((dataField) => (
              <DashboardPageNavSelectTableField
                dataField={dataField}
                selected={selectedFields?.includes(dataField.dataField) || false}
                onClick={() => {
                  const _selectedFields = selectedFields ?? [];

                  if (!_selectedFields.includes(dataField.dataField))
                    setSelectedFields([..._selectedFields, dataField.dataField]);
                  else
                    setSelectedFields(
                      produce(_selectedFields, (draft) => {
                        const index = draft.indexOf(dataField.dataField);
                        draft.splice(index, 1);
                      })
                    );
                }}
                searchString={searchString}
              />
            ))}
          </Stack>
        </Box>
      )}
    </Box>
  );
};
