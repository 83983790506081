import {
  Box,
  BoxProps,
  Chip,
  DbtDataFieldTypeIcon,
  Highlighter,
  Input,
  ListItemButton,
  Stack,
  Typography,
} from "components";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ellipsisStyle } from "consts";
import { DbtTable } from "models";
import { useState } from "react";
import { getSpacing } from "theme";
import produce from "immer";
import { ReportParamsDbTable } from "models/ReportParams";
import { DashboardPageNavSelectedTable } from "./DashboardPageNavSelectedTable";
import { useOrderableList } from "hooks";

type DashboardPageNavSelectedProps = Omit<BoxProps, "children"> & {
  selectedTables: ReportParamsDbTable[];
  setSelectedTables: (tables: ReportParamsDbTable[]) => void;
};

export const DashboardPageNavSelected = ({ selectedTables, setSelectedTables }: DashboardPageNavSelectedProps) => {
  const {
    onDraggingListItem,
    onDraggingListItemSx,
    onDraggingEnterListItem,
    onDraggingListItemEnd,
    draggingListItem,
    lastDraggedEnterListItem,
  } = useOrderableList<ReportParamsDbTable>({
    listItems: selectedTables,
    setListItems: (selectedTables) => setSelectedTables(selectedTables),
  });

  return (
    <Stack py={getSpacing("md")} px={getSpacing("sm")} spacing="sm" width="150px">
      {selectedTables.map((table) => {
        return (
          <Box
            key={table.name}
            draggable
            onDragStart={(e) => onDraggingListItem(e, table)}
            sx={onDraggingListItemSx(table)}
            onDragOver={(e) => onDraggingEnterListItem(e, table)}
            onDragEnd={(e) => onDraggingListItemEnd(e)}
            width="100%"
          >
            <DashboardPageNavSelectedTable table={table} />
          </Box>
        );
      })}
    </Stack>
  );
};
