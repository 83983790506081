import {
  Box,
  Paper,
  SortField,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "components";
import produce from "immer";
import { DbtDataField } from "models";
import { GeneratedReport } from "models/GeneratedReport";
import { PageSort } from "models/PageSort";
import { useRef } from "react";
import { DashboardPageDataGridSql } from "./DashboardPageDataGridSql";

type DashboardPageDataGridProps = {
  dataFields: DbtDataField[] | undefined;
  pageSort: PageSort;
  setPageSort: (pageSort: PageSort) => void;
  generatedReport: GeneratedReport | undefined;
};

export const DashboardPageDataGrid = ({
  dataFields,
  pageSort,
  setPageSort,
  generatedReport,
}: DashboardPageDataGridProps) => {
  const tableContainerRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  // if (selectedTable.selectedDataFields.length === 0) return null;

  return (
    <Paper sx={{ display: "flex", flexDirection: "column", overflow: "auto", height: "100%" }}>
      <TableContainer ref={tableContainerRef} sx={{ flexGrow: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {dataFields?.map((dataField) => {
                const displayName = dataField?.displayName || dataField?.dataField;
                const sortField = `${dataField?.tableName}.${dataField?.dataField}`;

                return (
                  <TableCell>
                    <SortField
                      displayName={displayName}
                      sortField={sortField}
                      sortingField={pageSort?.sortField}
                      sortingDesc={pageSort?.sortDesc}
                      onChange={(sortingField, sortingDesc) => {
                        setPageSort(
                          produce(pageSort, (draft) => {
                            draft.sortField = sortingField;
                            draft.sortDesc = sortingDesc;
                          })
                        );
                      }}
                    />
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {generatedReport?.dataFields?.map((row) => {
              return (
                <TableRow>
                  {dataFields?.map((headerCell) => {
                    return <TableCell>{row[`${headerCell.tableName}.${headerCell.dataField}`]}</TableCell>;
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        total={generatedReport?.total || 0}
        size={pageSort.pageSize}
        page={pageSort.page}
        onPageChange={(event, page) =>
          setPageSort(
            produce(pageSort, (draft) => {
              draft.page = page;
            })
          )
        }
        extras={
          <Stack spacing="sm">
            {generatedReport?.SQL && <DashboardPageDataGridSql sqlString={generatedReport?.SQL} />}
          </Stack>
        }
      />
    </Paper>
  );

  // return (
  //   <DxDataGrid dataSource={gridData} allowColumnReordering={true} showBorders={true} width="100%">
  //     {/* <Selection mode="multiple" /> */}
  //     {/* <Grouping autoExpandAll={true} /> */}
  //     <DxPaging defaultPageSize={10} />
  //     {/* <DxExport enabled={true} formats={exportFormats} allowExportSelectedData={true} /> */}

  //     {selectedTable?.selectedDataFields.map((x) => {
  //       return <DxColumn key={x.dataField} dataField={x.dataField} dataType={x.dataType} groupIndex={x.groupIndex} />;
  //     })}
  //   </DxDataGrid>
  // );
};
