import { useAuth } from "hooks";
import { BaseLayout, NavLayout, PublicLayout } from "layouts";
import { dashboardPageRoute } from "pages/DashboardPage/DashboardPage";
import { loginPageRoute } from "pages/LoginPage/LoginPage";
import { Navigate, RouteObject, useRoutes } from "react-router-dom";
import { lazyLoadingComponents } from "./components";

// Component Pages.
// Avoiding importing all components / import component as we need
// By declaring each component as lazy promise so that it gets imported on the spot
// only when that component is being routed to
const { LoginPage, DashboardPage } = lazyLoadingComponents();

// const HomePage = Loadable(lazy(() => import("pages/home/HomePage")));
// const LoginPage = Loadable(lazy(() => import("pages/login/LoginPage")));

// copies RouteObject from react-router-dom, with additional field
// export type RouteObject = {
//   children?: RouteObject[];
//   element?: React.ReactNode;
//   index?: boolean;
//   path?: string;
//   name?: string;
// };

const publicRoutes: RouteObject[] = [
  {
    path: loginPageRoute.template,
    element: <LoginPage />,
  },
];

const navRoutes: RouteObject[] = [
  { element: <Navigate to={dashboardPageRoute.template} replace />, index: true }, // default
  {
    path: dashboardPageRoute.template,
    element: <DashboardPage />,
  },
  // {
  //   path: developerListPageRoute.template,
  //   name: developerListPageRoute.name,
  //   element: <DeveloperListPage />,
  //   children: [
  //     {
  //       path: addDeveloperPageRoute.template,
  //       name: addDeveloperPageRoute.name,
  //       element: <DeveloperPage />,
  //     },
  //     {
  //       path: developerPageRoute.template,
  //       name: developerPageRoute.name,
  //       element: <DeveloperPage />,
  //     },
  //   ],
  // },
  // {
  //   path: ruleListPageRoute.template,
  //   name: ruleListPageRoute.name,
  //   element: <RuleListPage />,
  //   children: [
  //     {
  //       path: addRulePageRoute.template,
  //       name: addRulePageRoute.name,
  //       element: <AddRulePage />,
  //     },
  //     {
  //       path: rulePageRoute.template,
  //       name: rulePageRoute.name,
  //       element: <RulePage />,
  //     },
  //   ],
  // },
];

export const appRoutes: RouteObject[] = [
  {
    path: "",
    element: <BaseLayout />,
    children: [
      {
        path: "/",
        element: (
          <AuthCheck>
            <NavLayout />
          </AuthCheck>
        ),
        children: navRoutes,
      },
      // PublicLayout
      {
        path: "/",
        element: <PublicLayout />,
        children: publicRoutes,
      },
    ],
  },

  { path: "*", element: <Navigate to="/404" replace /> },
];

export function AppRoutes() {
  //const navigate = useNavigate();
  //const urlParams = new URLSearchParams(window.location.search);
  //let redirectTo = urlParams.get("redirectTo");

  // @ts-ignore
  return useRoutes(appRoutes);
}

function AuthCheck({ children }: any) {
  const { isAuthenticated, isLoading } = useAuth();

  if (isLoading) return null;

  if (isAuthenticated) return <>{children}</>;
  else return <Navigate to="login" />;
}
