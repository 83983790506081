import { overrideComponents } from './overrideComponents/components';
import { createTheme } from "@mui/material/styles";
import { PaletteOptions } from "@mui/material/styles/createPalette"; // need this

// A custom theme for this app
export let darkTheme = createTheme({
  spacing: 6, // 8 is default
  palette: {
    mode: "dark",
    // primary: {
    //   main: "rgb(13, 98, 141)", // logo color: #0d628d
    //   light: "#1e78df",
    //   dark: "#0b4a6a",
    // },
    // secondary: {
    //   main: '#19857b',
    // },
    // error: {
    //   main: red.A400,
    // },
    text: {
      primary: "rgba(255, 255, 255, 0.7)",
    },
    background: {
      default: "rgb(17, 25, 42)",
      paper: "rgb(17, 25, 42)",
      //neutral: "#fdfdfd",
      //hover: 'rgba(13, 98, 141, 0.04)'
    },
    border: {
      main: "rgba(0, 0, 0, 0.12)",
      light: "whitesmoke",
      dark: "black",
    },
  },
  zIndex: {},
});

declare module "@mui/material/styles" {
  interface Palette {
    border: {
      main: string;
      light: string;
      dark: string;
    };
  }
  interface PaletteOptions {
    border: {
      main: string;
      light: string;
      dark: string;
    };
  }
  // interface TypeBackground {
  //   neutral: string;
  // }
}

darkTheme.components = overrideComponents(darkTheme);
