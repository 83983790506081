import {
  AppTopBar,
  Box,
  //NavigationBar,
  Stack,
} from "components";
import { Outlet } from "react-router-dom";

export function NavLayout() {
  return (
    <Stack direction="row" minHeight="100vh">
      {/* <NavigationBar /> */}

      <Stack position="relative" width="100%">
        <AppTopBar />

        <Box position="relative" flexGrow={1} flexShrink={0}>
          <Outlet />
        </Box>
      </Stack>
    </Stack>
  );
}
