import { Box, Tooltip } from "components";
import { DbtDataFieldType } from "models";
import NumbersIcon from "@mui/icons-material/Numbers";
import AbcIcon from "@mui/icons-material/Abc";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";

type DbtDataFieldTypeIconProps = {
  type: DbtDataFieldType;
};
export const DbtDataFieldTypeIcon = ({ type }: DbtDataFieldTypeIconProps) => {
  const fontSize: string = "1.3rem";
  let comp: any = null;

  switch (type) {
    case "string":
      comp = <AbcIcon sx={{ fontSize: fontSize }} />;
      break;
    case "number":
      comp = <NumbersIcon sx={{ fontSize: fontSize }} />;
      break;
    case "date":
      comp = <CalendarTodayIcon sx={{ fontSize: fontSize }} />;
      break;
    case "datetime":
      comp = <DateRangeIcon sx={{ fontSize: fontSize }} />;
      break;
    case "boolean":
      comp = <DownloadDoneIcon sx={{ fontSize: fontSize }} />;
      break;
    default:
      comp = type;
      break;
  }

  return <Tooltip title={type}>{comp}</Tooltip>;
};
