import { Box, Button, Dialog, Stack, Typography } from "components";
import produce, { original } from "immer";
import { DbtFilter, DbtTable } from "models";
import { useEffect, useState } from "react";
import { getSpacing } from "theme";
import { DashboardPageFilter } from "./DashboardPageFilter";

type DashboardPageFiltersProps = {
  tables: DbtTable[] | undefined;
  filters: DbtFilter[][] | undefined;
  onApply: (filterSets: DbtFilter[][] | undefined) => void;
  onClose: () => void;
};

export const DashboardPageFilters = ({ tables, filters: filtersProp, onApply, onClose }: DashboardPageFiltersProps) => {
  const [filters, setFilters] = useState<DbtFilter[][]>();
  const [originalFilters, setOriginalFilters] = useState<DbtFilter[][]>();

  useEffect(() => {
    // init filters
    if (filtersProp && filtersProp.some((filterGroup) => filterGroup.length > 0)) {
      setFilters(filtersProp);
      setOriginalFilters(filtersProp);
    } else {
      setFilters([[initFilter()]]);
      setOriginalFilters([[initFilter()]]);
    }
  }, []);

  const initFilter = (): DbtFilter => {
    return { active: true, table: tables?.length === 1 ? tables[0].name : undefined };
  };

  const reset = () => {
    setFilters([]);
  };

  const apply = () => {
    onApply(filters);
    onClose();
  };

  const addFilter = (filterSet: DbtFilter[]): void => {
    setFilters(
      produce(filters, (draft) => {
        let draftFilterSet = draft!.find((x) => filterSet === original(x))!;
        draftFilterSet.push(initFilter());
      })
    );
  };

  const addFilterSet = (): void => {
    setFilters(!filters ? [[initFilter()]] : [...filters, [initFilter()]]);
  };

  const removeFilter = (filterSet: DbtFilter[], filter: DbtFilter): void => {
    if (filterSet.length === 1) {
      setFilters(
        produce(filters, (draft) => {
          let removingFilterSetIndex = draft!.findIndex((x) => filterSet === original(x))!;
          draft!.splice(removingFilterSetIndex, 1);
        })
      );
    } else {
      setFilters(
        produce(filters, (draft) => {
          let draftFilterSet = draft!.find((x) => filterSet === original(x))!;
          let removingFilterIndex = draftFilterSet.findIndex((x) => filter === original(x))!;
          draftFilterSet.splice(removingFilterIndex, 1);
        })
      );
    }
  };

  return (
    <>
      <Dialog open={true} onClose={onClose} maxWidth="lg" title="Filters">
        <Box p={getSpacing("lg")} height="100%">
          <Stack direction="column" spacing="md">
            {!filters?.length && (
              <Stack direction="row" spacing="sm" alignItems={"center"}>
                <Button variant="outlined" onClick={() => addFilterSet()}>
                  + Filter
                </Button>
              </Stack>
            )}

            {filters?.map((filterSet, filterSetIndex) => {
              const isLastFilterSet = filterSetIndex === filters.length - 1;

              return (
                <Stack key={filterSetIndex} spacing="sm" width="100%">
                  {filterSet?.map((filter, filterIndex) => {
                    return (
                      <DashboardPageFilter
                        key={filterIndex}
                        tables={tables}
                        filter={filter}
                        setFilter={(resultFilter: DbtFilter) =>
                          setFilters(
                            produce(filters, (draft) => {
                              draft![filterSetIndex][filterIndex] = { ...resultFilter };
                            })
                          )
                        }
                        removeFilter={() => removeFilter(filterSet, filter)}
                      />
                    );
                  })}

                  <Stack direction="row" spacing="sm" alignItems={"center"}>
                    <Button noMinWidth variant="outlined" onClick={() => addFilter(filterSet)}>
                      + AND
                    </Button>

                    {isLastFilterSet && (
                      <Button noMinWidth variant="outlined" onClick={() => addFilterSet()}>
                        + OR
                      </Button>
                    )}
                  </Stack>

                  {!isLastFilterSet && <Typography variant="h6">OR</Typography>}
                </Stack>
              );
            })}
          </Stack>

          <Stack direction="row" justifyContent="space-between" mt="100px">
            <Button onClick={onClose}>Close</Button>

            <Stack spacing="sm" direction="row" justifyContent="flex-end">
              <Button variant="outlined" onClick={() => reset()}>
                Reset
              </Button>

              <Button
                variant="contained"
                onClick={() => apply()}
                disabled={JSON.stringify(originalFilters) === JSON.stringify(filters)}
                type="submit"
              >
                Apply
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Dialog>
    </>
  );
};
