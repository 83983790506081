import { DialogContent } from "./DialogContent";
import { Theme } from "@mui/material/styles";
import { Table, Grid, Paper, Card, Drawer, Button } from "../overrideComponents";

export const overrideComponents = (theme: Theme) => {
  return Object.assign(
    Button(theme),
    Card(theme),
    DialogContent(theme),
    Drawer(theme),
    Grid(theme),
    Paper(theme),
    Table(theme),
  );
};
