import { Button as MuiButton, ButtonProps as MuiButtonProps, SxProps, Theme } from "@mui/material";

export type ButtonProps = MuiButtonProps & {
  noMinWidth?: boolean;
};

export const Button = ({ noMinWidth, children, ...rest }: ButtonProps) => {
  return (
    <MuiButton {...rest} sx={{ minWidth: noMinWidth ? "auto !important" : null }}>
      {children}
    </MuiButton>
  );
};
