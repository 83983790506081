export const FE_SEARCH_DELAY_MS = 200;

var IS_LOCAL_MOCK_ON = false; // Toggle this to show local mock in app (no auth + fixture api responses)
// IS_LOCAL_MOCK_ON = true;
export const IS_LOCAL = process.env.NODE_ENV === "development";
export const USE_LOCAL_MOCK = IS_LOCAL_MOCK_ON && IS_LOCAL;

export const isLocalhostWeb = Boolean(
  window?.location?.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window?.location?.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window?.location?.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

type APP_MODE = "local_web" | "local_pwa" | "prod" | "dev";

export function getAppMode(): APP_MODE {
  if (window?.location?.hostname?.includes("192.168")) {
    return "local_pwa";
  }

  return process.env.REACT_APP_MODE as APP_MODE;
}

export function getAppVersion() {
  return process.env.REACT_APP_VERSION;
}

export function getRedirectLink() {
  if (getAppMode() === "local_web") {
    return "http://localhost:3000";
  }

  if (getAppMode() === "local_pwa") {
    return process.env.REACT_APP_LOCAL_IP;
  }

  if (getAppMode() === "dev") {
    return "https://bi-reports.dev.ensomata.com";
  }

  // prod
  return "https://bi-reports.ensomata.com";
}

export const IS_PROD = getAppMode() === "prod";

export const LOCALSTORAGE_DBT_TABLES = "DBT_TABLES";
