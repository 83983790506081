import { Box, IconButton, Popover, Typography } from "components";
import { useTheme } from "hooks";
import React from "react";
import { useState } from "react";

type DashboardPageDataGridSqlProps = {
  sqlString: string | undefined;
};

export const DashboardPageDataGridSql = ({ sqlString }: DashboardPageDataGridSqlProps) => {
  const keywords = ["SELECT", "FROM", "LEFT JOIN", "ON", "WHERE", "LIMIT", "GROUP BY", "ORDER BY"];
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();

  const formatSqlString = (sql) => {
    const formattedSql = keywords.reduce((acc, keyword) => {
      const regex = new RegExp(`\\s*(${keyword})\\s*`, "gi");
      return acc.replace(regex, `§$1§ `);
    }, sql);

    return formattedSql.split("§");
  };

  const formattedSqlComponents = formatSqlString(sqlString);

  return (
    <Box>
      <IconButton
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          setAnchorEl(event.currentTarget);
        }}
      >
        <Typography variant="caption">SQL</Typography>
      </IconButton>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Box maxWidth="500px">
          <Typography sx={{ p: 2, whiteSpace: "pre-wrap" }}>
            {formattedSqlComponents.map((component, index) => {
              const isKeyword = keywords.includes(component.trim());
              return (
                <React.Fragment key={index}>
                  {isKeyword && index > 0 && "\n"}
                  <Box
                    display="inline-flex"
                    color={isKeyword ? theme.palette.primary.main : "inherit"}
                    fontWeight={isKeyword ? "medium" : "inherit"}
                  >
                    {component}
                  </Box>
                </React.Fragment>
              );
            })}
          </Typography>
        </Box>
      </Popover>
    </Box>
  );
};
