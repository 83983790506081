import { Page, Box, Button } from "components";
import { useAuth } from "hooks";

export const loginPageRoute = {
  template: "login",
  getPath: () => {
    return "login";
  },
};

export const LoginPage = () => {
  const { handleLogin } = useAuth();

  return (
    <Page>
      <Box display="flex" height="100vh" alignItems="center" justifyContent="center">
        <Button variant="contained" onClick={handleLogin}>
          Login with SSO
        </Button>
      </Box>
    </Page>
  );
};
