import { SxProps } from "@mui/material";
import { Theme } from "@emotion/react";
import { useState } from "react";
import { useTheme } from "hooks";

type useOrderableListProps<T> = {
  listItems: T[] | undefined;
  setListItems: (listItems: T[]) => void;
};

export const useOrderableList = <T extends object>({
  listItems,
  setListItems,
}: useOrderableListProps<T>): {
  onDraggingListItem: (e: React.DragEvent<any>, listItem: T) => void;
  onDraggingEnterListItem: (e: React.DragEvent<any>, listItem: T) => void;
  onDraggingListItemEnd: (e: React.DragEvent<any>) => void;
  onDraggingListItemSx: (listItem: T) => SxProps<Theme>;
  draggingListItem: T | undefined;
  lastDraggedEnterListItem: T | undefined;
} => {
  const theme = useTheme();
  
  const [draggingListItem, setDraggingListItem] = useState<T>();
  const [lastDraggedEnterListItem, setLastDraggedOverListItem] = useState<T>();

  const onDraggingListItem = (e: React.DragEvent<HTMLDivElement>, listItem: T) => {
    setDraggingListItem(listItem);
  };

  const onDraggingListItemSx = (listItem: T) => {
    let sx: SxProps<Theme> = {
      transition: "transform 250ms ease-in-out",
      border: draggingListItem === listItem ? `1px solid ${theme.palette.secondary.dark} !important` : undefined,
      opacity: draggingListItem === listItem ? 0.7 : 1,
      backgroundColor: draggingListItem === listItem ? theme.palette.secondary.light : undefined,
      transform: draggingListItem === listItem ? "scale(0.9)" : "scale(1)",
    };

    return sx;
  };

  const onDraggingEnterListItem = (e: React.DragEvent<any>, listItem: T) => {
    e.preventDefault();
    const draggingEnterListItem = listItem;

    if (draggingListItem !== draggingEnterListItem)
      setLastDraggedOverListItem(draggingEnterListItem);

    if (listItems) {
      const draggingListItemIndex = listItems.indexOf(draggingListItem!);
      const draggingEnterListItemIndex = listItems.indexOf(draggingEnterListItem);

      let newListItems: T[] = [];
      if (draggingEnterListItemIndex < draggingListItemIndex) {
        //@ts-ignore
        newListItems = [
          ...listItems.slice(0, draggingEnterListItemIndex),
          draggingListItem,
          ...listItems.slice(draggingEnterListItemIndex, draggingListItemIndex),
          ...listItems.slice(draggingListItemIndex + 1),
        ];
      } else {
        //@ts-ignore
        newListItems = [
          ...listItems.slice(0, draggingListItemIndex),
          ...listItems.slice(draggingListItemIndex + 1, draggingEnterListItemIndex + 1),
          draggingListItem,
          ...listItems.slice(draggingEnterListItemIndex + 1),
        ];
      }

      setListItems(newListItems);
    }
  };

  const onDraggingListItemEnd = (e: React.DragEvent<any>) => {
    setDraggingListItem(undefined);
    setLastDraggedOverListItem(undefined);
  };

  return {
    onDraggingListItem: (e: React.DragEvent<any>, listItem: T) => onDraggingListItem(e, listItem),
    onDraggingEnterListItem: (e: React.DragEvent<any>, listItem: T) => onDraggingEnterListItem(e, listItem),
    onDraggingListItemEnd: (e: React.DragEvent<any>) => onDraggingListItemEnd(e),
    onDraggingListItemSx: (listItem: T) => onDraggingListItemSx(listItem),
    draggingListItem: draggingListItem,
    lastDraggedEnterListItem: lastDraggedEnterListItem,
  };
};
