import {
  Box,
  BoxProps,
  Chip,
  DbtDataFieldTypeIcon,
  Highlighter,
  Input,
  ListItemButton,
  Stack,
  Typography,
} from "components";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ellipsisStyle } from "consts";
import { DbtTable } from "models";
import { useState } from "react";
import { getSpacing } from "theme";
import produce from "immer";
import { ReportParamsDbTable } from "models/ReportParams";

type DashboardPageNavSelectedTableProps = Omit<BoxProps, "children"> & {
  table: ReportParamsDbTable;
};

export const DashboardPageNavSelectedTable = ({ table }: DashboardPageNavSelectedTableProps) => {
  const displayName = table.displayName || table.name;

  return (
    <Chip
      color="primary"
      variant="filled"
      fullWidthLabel
      sx={{ width: "100%" }}
      label={
        <Stack>
          {displayName}

          {/* <Stack direction="column" spacing="sm">
            {table?.fields?.map((field) => {
              return <Box>{field}</Box>;
            })}
          </Stack> */}
        </Stack>
      }
    />
  );
};
