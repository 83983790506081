import { BoxProps, IconButton } from "@mui/material";
import { Typography, Chip } from "../../components";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import { useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

type FilterButtonProps = Omit<BoxProps, "children"> & {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  filtersCount?: number | undefined;
};

export const FilterButton = ({ className, onClick, filtersCount, ...rest }: FilterButtonProps) => {
  return (
    <IconButton onClick={onClick} color={filtersCount && filtersCount > 0 ? "primary" : undefined} sx={{ position: "relative" }}>
      <FilterListRoundedIcon sx={{ fontSize: "28px" }} />
      {filtersCount !== undefined && filtersCount > 0 && (
        <Typography variant="subtitle1" position="absolute" top="-0px" right="-0px">
          {/* <CheckCircleIcon color="primary" sx={{ fontSize: "1.2rem" }} /> */}
          {/* <Chip label={filtersCount} color="primary" size="small" /> */}
          {filtersCount}
        </Typography>
      )}
    </IconButton>
  );
};
