import {
  Box,
  BoxProps,
  Chip,
  Collapse,
  DbtDataFieldTypeIcon,
  Highlighter,
  Input,
  List,
  ListItemButton,
  Skeleton,
  Stack,
  Typography,
} from "components";

import { useDebounce, useTheme } from "hooks";
import produce, { original } from "immer";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { deepCopy } from "services";
import { getSpacing } from "theme";
import { DbtDataField, DbtTable } from "models";
import { useState } from "react";
import httpRequestService from "services/httpRequestService";
import { ellipsisStyle, FE_SEARCH_DELAY_MS } from "consts";
import { tab } from "@testing-library/user-event/dist/tab";
import { ReportParamsDbTable } from "models/ReportParams";
import { DashboardPageNavSelectTable } from "./DashboardPageNavSelectTable";

type DashboardPageNavSelectProps = Omit<BoxProps, "children"> & {
  tablesLoading: boolean;
  tables: DbtTable[] | undefined;
  selectedTables: ReportParamsDbTable[];
  setSelectedTables: (tables: ReportParamsDbTable[]) => void;
};

export const DashboardPageNavSelect = ({
  tablesLoading,
  tables,
  selectedTables,
  setSelectedTables,
}: DashboardPageNavSelectProps) => {
  const theme = useTheme();
  const [searchString, setSearchString] = useState<string>("");
  const [hoveredTableName, setHoveredTableName] = useState<string | undefined>(undefined);

  return (
    <Stack
      py={getSpacing("md")}
      direction="column"
      spacing="md"
      width="250px"
      borderRight={`1px solid ${theme.palette.border.main}`}
    >
      <Box px={getSpacing("sm")}>
        <Input
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
          placeholder="Search"
          size="small"
          autoComplete="off"
        />
      </Box>

      <Stack px={getSpacing("md")} overflow={tablesLoading ? "hidden" : "auto"} spacing="sm">
        {tablesLoading
          ? Array.from({ length: 50 }).map((_, index) => (
              <Skeleton key={index} variant="rounded" height={30} sx={{ flexGrow: 0, flexShrink: 0 }} />
            ))
          : tables
              ?.filter(
                (x) =>
                  x.name.toLowerCase().includes(searchString.toLowerCase()) ||
                  x.displayName?.toLowerCase().includes(searchString.toLowerCase()) ||
                  x.dataFields?.some(
                    (y) =>
                      y.dataField.toLowerCase().includes(searchString.toLowerCase()) ||
                      y.displayName?.toLowerCase().includes(searchString.toLowerCase())
                  )
              )
              .map((table) => {
                const selectedTable = selectedTables?.find((x) => x.name === table.name);

                return (
                  <Box
                    onMouseEnter={() => setHoveredTableName(table.name)}
                    onMouseLeave={() => setHoveredTableName(undefined)}
                  >
                    <DashboardPageNavSelectTable
                      key={table.name}
                      table={table}
                      isTableSelected={selectedTable !== undefined}
                      selectedFields={selectedTable?.fields}
                      setSelectedFields={(selectedFields) => {
                        if (selectedTable) {
                          if (selectedFields.length > 0) {
                            setSelectedTables(
                              produce(selectedTables, (draft) => {
                                const index = draft.findIndex((x) => x.name === table.name);
                                if (index >= 0) draft[index].fields = selectedFields;
                              })
                            );
                          } else {
                            setSelectedTables(
                              produce(selectedTables, (draft) => {
                                const index = draft.findIndex((x) => x.name === table.name);
                                draft.splice(index, 1);
                              })
                            );
                          }
                        } else {
                          const _selectedTable = {
                            name: table.name,
                            displayName: table.displayName,
                            fields: selectedFields,
                          } as ReportParamsDbTable;

                          setSelectedTables([...selectedTables, _selectedTable]);
                        }
                      }}
                      searchString={searchString}
                      hovered={hoveredTableName === table.name}
                    />
                  </Box>
                );
              })}
      </Stack>
    </Stack>
  );
};
