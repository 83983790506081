import { Box, Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from "@mui/material";

import { ReactNode, useRef } from "react";
import { Instance } from "@popperjs/core";

type TooltipProps = MuiTooltipProps & { children: ReactNode };

export const Tooltip = (props: TooltipProps) => {
  const positionRef = useRef<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const popperRef = useRef<Instance>(null);
  const areaRef = useRef<HTMLDivElement>(null);

  const handleMouseMove = (event: React.MouseEvent) => {
    positionRef.current = { x: event.clientX, y: event.clientY };

    if (popperRef.current != null) {
      popperRef.current.update();
    }
  };

  const { sx, className, ...rest } = props;
  return (
    <MuiTooltip
      enterDelay={300}
      sx={{ ...sx }}
      placement="top"
      arrow
      PopperProps={{
        popperRef,
        anchorEl: {
          getBoundingClientRect: () => {
            return new DOMRect(positionRef.current.x, areaRef.current!.getBoundingClientRect().y, 0, 0);
          },
        },
      }}
      {...rest}
    >
      <Box ref={areaRef} onMouseMove={handleMouseMove} display="inline-flex">
        {props.children}
      </Box>
    </MuiTooltip>
  );
};
