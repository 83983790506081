import { BoxProps, Chip, DbtDataFieldTypeIcon, Highlighter, Stack, Typography } from "components";

import { ellipsisStyle } from "consts";
import { DbtDataField } from "models";

type DashboardPageNavSelectTableFieldProps = Omit<BoxProps, "children"> & {
  dataField: DbtDataField;
  selected: boolean;
  onClick: () => void;
  searchString: string;
};

export const DashboardPageNavSelectTableField = ({
  dataField,
  selected,
  onClick,
  searchString,
}: DashboardPageNavSelectTableFieldProps) => {
  const dataFieldDisplayName = dataField.displayName || dataField.dataField;

  return (
    <Chip
      fullWidthLabel
      label={
        <Stack direction="row" spacing="xs" alignItems={"center"} justifyContent="space-between" width="100%">
          <DbtDataFieldTypeIcon type={dataField.dataType} />

          <Stack
            direction="row"
            spacing="sm"
            alignItems="center"
            justifyContent="flex-start"
            {...ellipsisStyle}
            flexGrow={1}
          >
            <Highlighter searchWords={[searchString]} textToHighlight={dataFieldDisplayName} />
            <Typography fontWeight="medium"></Typography>
          </Stack>
        </Stack>
      }
      onClick={onClick}
      color="success"
      variant={selected ? "filled" : "outlined"}
    />
  );
};
