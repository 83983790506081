import { Autocomplete, AutocompleteRenderOptionState, BoxProps, TextField } from "@mui/material";
import { ReactNode, useState } from "react";

// here we are not extending the because we are expanding on more than one MUI components
// lets just define properties here as we need
type SelectProps = Omit<BoxProps, "children" | "onChange"> & {
  getOptionLabel?: (option: any) => string;
  groupBy?: (option: any) => string;
  onChange?: (e: any, value: any) => void;
  label?: string;
  required?: boolean;
  value?: any;
  errors?: any[];
  options?: any[];
  defaultValue?: any;
  multiple?: boolean;
  disabled?: boolean;
  disableClearable?: boolean;
  isOptionEqualToValue?: ((option: any, value: any) => boolean) | undefined;
  fetchFunction?: any;
  placeholder?: string;
  autoComplete?: string;
  renderOption?:
    | ((
        props: React.HTMLAttributes<HTMLLIElement>,
        option: any,
        state: AutocompleteRenderOptionState
      ) => React.ReactNode)
    | undefined;
  size?: "small" | "medium" | undefined;
  noOptionsText?: ReactNode;
};

export const Select = ({
  getOptionLabel,
  groupBy,
  onChange,
  label,
  required,
  value,
  errors,
  options,
  defaultValue,
  multiple,
  disabled,
  disableClearable,
  fetchFunction,
  isOptionEqualToValue,
  placeholder,
  autoComplete,
  renderOption,
  size = "medium",
  noOptionsText,
  ...rest
}: SelectProps) => {
  const [typeDelayTimeoutId, setTypeDelayTimeoutId] = useState<any>();
  const [fetchOptions, setFetchOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [firstTimeOpen, setFirstTimeOpen] = useState<boolean>(true);

  // do some checking here
  if (options && fetchFunction) {
    throw new Error("options and fetchFunction props cannot be used together.");
  }

  if (fetchFunction) {
    if (!isOptionEqualToValue) {
      throw new Error("isOptionEqualToValue prop must be provided if fetchFunction prop is used. ");
    }
  }

  // format value
  let _value = value;

  if (!_value) {
    if (multiple) {
      _value = [];
    } else {
      _value = null;
    }
  }

  const errorMsg = errors && errors?.length > 0 ? errors[0] : null;
  const hasError = errorMsg ? true : false;
  const isFetch = fetchFunction ? true : false; // this indicates whether we are grabbing the data from API

  const handleInputChange = (value: string) => {
    if (isFetch) {
      setLoading(true);
      setFetchOptions([]);

      clearTimeout(typeDelayTimeoutId);
      const _typeDelayTimeoutId = setTimeout(function () {
        getFetchOptions(value);
      }, 500); // wait for user to finish typing input: how many ms to wait
      setTypeDelayTimeoutId(_typeDelayTimeoutId);
    }
  };

  const getFetchOptions = (searchString: string) => {
    fetchFunction(searchString).then((response: any) => {
      setFetchOptions(response.data);
      setLoading(false);
    });
  };

  const getOptions = () => {
    if (isFetch) {
      return fetchOptions ? fetchOptions : [];
    } else {
      return options ? options : [];
    }
  };

  const onOpen = () => {
    if (isFetch) {
      if (firstTimeOpen) {
        setFirstTimeOpen(false);
        getFetchOptions("");
      }
    }
  };

  return (
    <Autocomplete
      disablePortal={false}
      multiple={multiple}
      value={_value}
      //value={props.value ? props.value : (null as any)}
      defaultValue={defaultValue}
      fullWidth
      options={getOptions()}
      getOptionLabel={getOptionLabel}
      groupBy={groupBy}
      renderOption={renderOption}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={hasError}
          helperText={errorMsg}
          required={required}
          disabled={disabled}
          autoComplete={autoComplete}
          placeholder={placeholder}
          size={size}
        />
      )}
      disableClearable={disableClearable || required}
      onChange={onChange}
      onInputChange={(e, value) => handleInputChange(value)}
      loading={loading}
      onOpen={() => onOpen()}
      isOptionEqualToValue={isOptionEqualToValue}
      //filterSelectedOptions={true} // hide options already selected
      disableCloseOnSelect={multiple ? true : false}
      //autoHighlight
      noOptionsText={noOptionsText ? noOptionsText : undefined}
    />
  );
};
