import { Table, TableBody, TablePagination as MuiTablePagination, TableRow, useTheme } from "@mui/material";
import { TableCell } from "components";

type TablePaginationProps = {
  total: number;
  size: number;
  page: number;
  onPageChange: (event: any, page: number) => void;
  extras?: React.ReactNode;
};

export const TablePagination = ({ total, size, page, onPageChange, extras }: TablePaginationProps) => {
  const theme = useTheme();

  const renderPagination = () => {
    return (
      <MuiTablePagination
        sx={{
          display: "flex",
          justifyContent: "right",
          borderTop: `1px solid ${theme.palette.divider}`,
          borderBottom: "none",
          flexShrink: 0,
        }}
        rowsPerPageOptions={[]}
        count={total}
        rowsPerPage={size}
        page={page - 1}
        onPageChange={(event, page) => onPageChange(event, page + 1)}
      />
    );
  };

  return (
    <Table sx={{ borderTop: `solid 1px ${theme.palette.divider}` }}>
      <TableBody>
        <TableRow>
          {extras && <TableCell>{extras}</TableCell>}
          {renderPagination()}
        </TableRow>
      </TableBody>
    </Table>
  );
};
