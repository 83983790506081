import { Box, BoxProps, SxProps, Theme, useTheme } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { clickableStyle } from "consts";

type SortFieldProps = Omit<BoxProps, "children" | "onChange"> & {
  displayName: string;
  sortField: string;
  sortingField: string | undefined;
  sortingDesc: boolean | undefined;
  onChange: (sortingField: string, sortingDesc: boolean) => void;
};

export const SortField = ({ displayName, sortField, sortingField, sortingDesc, onChange, sx, ...rest }: SortFieldProps) => {
  const theme = useTheme();

  const handleClick = () => {
    if (sortingField === sortField) {
      onChange(sortingField, !sortingDesc)
    }
    else
    {
      onChange(sortField, false);
    }
  };

  //#region styles
  const arrowDropIconStyle: SxProps<Theme> = {
    position: "absolute",
    display: "flex",
    fontSize: "26px",
    opacity: ".2",
    height: "100%",
    bottom: "0",
    alignItems: "center",
  };

  const arrowDropUpIconStyle: SxProps<Theme> = Object.assign({}, arrowDropIconStyle, { top: "-3px" });
  const arrowDropUpDownStyle: SxProps<Theme> = Object.assign({}, arrowDropIconStyle, { top: "3px" });
  //#endregion

  return (
    <Box sx={{ ...sx, display: "inline-flex", ...clickableStyle }} onClick={() => handleClick()}>
      {displayName}

      <Box position="relative" sx={{ display: "inline-flex", flexDirection: "column", width: "22px" }}>
        <ArrowDropUpIcon sx={Object.assign({}, arrowDropUpIconStyle, sortingField === sortField && sortingDesc === false && { opacity: "1" })} />
        <ArrowDropDownIcon sx={Object.assign({}, arrowDropUpDownStyle, sortingField === sortField && sortingDesc === true && { opacity: "1" })} />
      </Box>
    </Box>
  );
};
