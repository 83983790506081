import { ThemeProvider } from "@mui/material/styles";
import {
  AppTopBarBrand,
  AppTopBarUserMenu,
  Box,
  BoxProps,
  Paper,
  //NavigationBar,
  Stack,
} from "components";
import { darkTheme } from "theme";

type AppTopBarProps = Omit<BoxProps, "children"> & {};

export const AppTopBar = ({ className, sx, ...rest }: AppTopBarProps) => {
  return (
    <ThemeProvider theme={darkTheme}>
      <Paper sx={{ position: "relative", borderRadius: "0px" }}>
        <Stack direction="row" px={2} py={4} height="50px" display="flex" alignItems="center" justifyContent="space-between">
          <AppTopBarBrand />

          <AppTopBarUserMenu />
        </Stack>
      </Paper>
    </ThemeProvider>
  );
};
