import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  //NavigationBar,
  Stack,
} from "components";
import { useAuth } from "hooks";
import { useState } from "react";
export const AppTopBarUserMenu = () => {
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<null | HTMLElement>(null);
  const { handleLogout } = useAuth();

  return (
    <Stack direction="row" px={2} height="50px" display="flex" alignItems="center">
      <Box ml="auto">
        <IconButton onClick={(e) => setUserMenuAnchorEl(e.currentTarget)}>
          <Avatar sx={{ width: 30, height: 30 }}></Avatar>
        </IconButton>

        <Menu anchorEl={userMenuAnchorEl} open={Boolean(userMenuAnchorEl)} onClose={() => setUserMenuAnchorEl(null)}>
          {/* <MenuItem onClick={() => {}}>Placeholder</MenuItem> */}
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Box>
    </Stack>
  );
};
