import { Box, BoxProps, Collapse, Skeleton, Stack } from "components";

import { DbtTable } from "models";
import { ReportParamsDbTable } from "models/ReportParams";
import { DashboardPageNavSelect } from "./DashboardPageNavSelect/DashboardPageNavSelect";
import { DashboardPageNavSelected } from "./DashboardPageNavSelected/DashboardPageNavSelected";

type DashboardPageNavProps = Omit<BoxProps, "children"> & {
  open: boolean;
  tablesLoading: boolean;
  tables: DbtTable[] | undefined;
  selectedTables: ReportParamsDbTable[];
  setSelectedTables: (tables: ReportParamsDbTable[]) => void;
};

export const DashboardPageNav = ({
  open,
  tablesLoading,
  tables,
  selectedTables,
  setSelectedTables,
}: DashboardPageNavProps) => {
  return (
    <Stack direction="row" sx={{ height: "100%" }}>
      <Collapse
        in={open}
        orientation={"horizontal"}
        sx={{ height: "100%" }}
        //collapsedSize={40}
      >
        <Stack direction="row" height="100%">
          <DashboardPageNavSelect
            tablesLoading={tablesLoading}
            tables={tables}
            selectedTables={selectedTables}
            setSelectedTables={setSelectedTables}
          />

          <Collapse in={selectedTables?.length > 1} orientation={"horizontal"} sx={{ height: "100%" }}>
            <DashboardPageNavSelected selectedTables={selectedTables} setSelectedTables={setSelectedTables} />
          </Collapse>

          {/* {!open && <Box>asdasd</Box>} */}
        </Stack>
      </Collapse>

      {/* <Stack direction="row" alignItems="center" sx={{ width: "50px", height: "100%" }}>
          asd
        </Stack> */}
    </Stack>
  );
};
