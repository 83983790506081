import { createSlice } from "@reduxjs/toolkit";

interface AppState {
  // general
  loading: number;
}

const initialState: AppState = {
  loading: 0,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setAppLoading: (state, action: { type: string; payload: typeof initialState.loading }) => {
      state.loading = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  setAppLoading
} = appSlice.actions;

export default appSlice.reducer;
