import { Dialog as MuiDialog, DialogProps as MuiDialogProps, DialogTitle, Paper, PaperProps } from "@mui/material";
import { ReactNode } from "react";
import Draggable from "react-draggable";
import { useTheme } from "../../hooks";

type DialogProps = MuiDialogProps & { width?: string; title?: ReactNode };

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export const Dialog = ({ onClose, transitionDuration, title, width, sx, children, ...rest }: DialogProps) => {
  const theme = useTheme();

  const handleClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
    // disabled backdrop click
    if (reason === "backdropClick") {
    } else {
      onClose?.(event, reason);
    }
  };

  const handleBackdropClick = (event) => {
    event.stopPropagation();
    return false;
  };

  return (
    <MuiDialog
      {...rest}
      PaperComponent={PaperComponent}
      transitionDuration={{
        appear: 0,
        enter: 0,
        exit: 0,
      }}
      sx={{
        "& .MuiDialog-paper": {
          width: width ? width : "80%",
        },
        ...sx,
      }}
      onClose={handleClose}
      onBackdropClick={handleBackdropClick}
    >
      <DialogTitle
        style={{ cursor: "move" }}
        id="draggable-dialog-title"
        sx={{ backgroundColor: theme.palette.background.default }}
        //sx={{ position: "absolute", right: 0, top: 0 }}
      >
        {title}
      </DialogTitle>

      {children}
    </MuiDialog>
  );
};
