import { Authenticator } from "@aws-amplify/ui-react"; // Configure Amplify
import { ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "redux/store";
import { AppRoutes } from "routes";
import { theme } from "theme";
import "./App.css";
import "./auth"; // Configure Amplify

export default function App() {
  return (
    <Provider store={store}>
      <Authenticator.Provider>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </ThemeProvider>
      </Authenticator.Provider>
    </Provider>
  );
}
