import { Stack } from "components";
import { useTheme } from "hooks";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

export const AppTopBarBrand = () => {
  const theme = useTheme();
  //const appTenantName = useSelector((state: RootState) => state.app.tenantName);

  return (
    <Stack direction="row" spacing="sm" alignItems="center">
      <img width="60px" src="ensomata-word-logo-white.svg" />
    </Stack>
  );
};
