import { Theme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export const DialogContent = (theme: Theme) => {
  return {
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingTop: "7px !important"
        },
      },
    }
  };
}
