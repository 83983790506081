import { ThemeProvider } from "@mui/material";
import { Stack, Box, Paper } from "components";
import { ReactNode } from "react";
import { darkTheme } from "theme";
import { useTheme } from "../../hooks";

type PageProps = {
  header?: ReactNode;
  nav?: ReactNode;
  isForm?: boolean;
  children?: ReactNode;
};

export const Page = ({ header, nav, isForm, children }: PageProps) => {
  const theme = useTheme();

  const renderNav = () => {
    return (
      //<ThemeProvider theme={darkTheme}>
      <Paper
        sx={{
          position: "relative",
          //height: "100vh",
          borderRadius: "0px",
          overflow: "auto",
          flexShrink: 0,
          zIndex: theme.zIndex.appBar,
        }}
      >
        {nav}
      </Paper>
      //</ThemeProvider>
    );
  };
  return (
    <Stack
      direction="row"
      sx={{
        position: "absolute",
        width: "100%",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 100,
        display: "flex",
        //flexDirection: "column",
        background: theme.palette.background.default,
      }}
      className="page"
    >
      {nav && renderNav()}
      
      <Box display="flex" flexDirection="column" flexGrow={1} height="100%" overflow="auto">
        {header && (
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            px={3}
            py={1.5}
            minHeight="60px"
            justifyContent="center"
          >
            {header}
          </Box>
        )}

        {children && (
          <Box
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              flexShrink: 1,
              overflow: "auto",

              px: 3,
              pt: 1,
              pb: 3,
            }}
          >
            {children}
          </Box>
        )}
      </Box>
    </Stack>
  );
};
