import {
  Button,
  FilterButton,
  IconButton,
  Page,
  PageHeader,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "components";

import "devextreme-fix.css";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { useTheme } from "hooks";
import produce from "immer";
import { DbtDataField, DbtFilter, DbtTable, isDbtFilterValid } from "models";
import { ReportParams, ReportParamsDbTable, gridTypes } from "models/ReportParams";
import { useEffect, useState } from "react";
import httpRequestService from "services/httpRequestService";
import { DashboardPageNav } from "./DashboardPageNav/DashboardPageNav";

import { DashboardPageFilters } from "./DashboardPageFilters/DashboardPageFilters";
import { DashboardPageDataGrid } from "../DashboardPageDataGrid/DashboardPageDataGrid";
import { customers } from "./fake-data/customers";
import { PageSort } from "models/PageSort";
import { GeneratedReport } from "models/GeneratedReport";

import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

export const dashboardPageRoute = {
  template: "dashboard",
  getPath: () => {
    return "dashboard";
  },
};

const baseUrl: string = `${process.env.REACT_APP_API_ENDPOINT}/cgi-bin/DevEx_Query_V8.pl`;
//const baseUrl: string = "http://35.153.166.141/cgi-bin/DevEx_Query_V8.pl";

export const DashboardPage = () => {
  const theme = useTheme();

  const [tables, setTables] = useState<DbtTable[]>();
  const [tablesLoading, setTablesLoading] = useState<boolean>(false);
  const [navOpen, setNavOpen] = useState<boolean>(true);

  const [originalReportParams, setOriginalReportParams] = useState<ReportParams>();
  const [reportParams, setReportParams] = useState<ReportParams>({
    gridType: "Data Table",
    dbTables: [],
    pageSort: {
      page: 1,
      pageSize: 100,
    },
    filters: [],
  });

  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);
  const [generatedReport, setGeneratedReport] = useState<GeneratedReport>();

  useEffect(() => {
    getDbtTables();

    // comment out: this selects a table and some fields
    setReportParams(
      produce((draft) => {
        draft.dbTables = [
          {
            name: "items",
            fields: ["enddate", "itemname", "itemdesc", "price"],
          },
          {
            name: "itemdetail",
            fields: ["itemid", "propid"],
          },
        ];
      })
    );
  }, []);

  // when the selected tables get removed:
  // remove filters that don't have a table
  // useEffect(() => {
  //   setReportParams(
  //     produce(reportParams, (draft) => {
  //       const tableNames = draft.dbTables.map((table) => table.name);

  //       if (draft.filters) {
  //         draft.filters = draft.filters.map((filterGroup) => {
  //           return filterGroup.filter((filter) => {
  //             return tableNames.includes(filter.table!);
  //           });
  //         });
  //       }
  //     })
  //   );
  // }, [reportParams?.dbTables?.length]);

  const getDbtTables = () => {
    setTablesLoading(true);

    httpRequestService(`${baseUrl}`, {
      method: "GET",
    }).then((response) => {
      setTables(response.data);
      setTablesLoading(false);
    });

    // let dbtTables = [tempDbtTable1, tempDbtTable2];

    // const savedDbtTablesString: string | null = localStorage.getItem(LOCALSTORAGE_DBT_TABLES);
    // let savedDbtTables: DbtTable[] | undefined;
    // if (savedDbtTablesString) {
    //   savedDbtTables = JSON.parse(savedDbtTablesString);
    //   if (savedDbtTables) {
    //     dbtTables = dbtTables.concat(savedDbtTables);
    //   }
    // }

    // setTables(dbtTables);
  };

  const getGridData = (reportParams: ReportParams) => {
    setOriginalReportParams(reportParams);
    //tableContainerRef?.current?.scrollTo(0, 0); // scroll table to the top

    httpRequestService(`${baseUrl}`, {
      method: "POST",
      data: {
        html: reportParams.gridType === "Data Table" ? 0 : 1,
        verbose: 0,
        dbTables: reportParams.dbTables,
        page: reportParams.pageSort.page,
        pageSize: reportParams.pageSort.pageSize,
        sortField: reportParams.pageSort.sortField,
        sortDesc: reportParams.pageSort?.sortDesc?.toString(),
        filters: toValidFilters(reportParams.filters),
      },
    }).then((response) => {
      setGeneratedReport(response.data);
    });
  };

  // gets the fields for the selected tables, and transform the fields to be DbtDataField type so we could acesss more info about the field
  const getSelectedTableFields = (includeAllFields?: boolean): DbtDataField[] | undefined => {
    let dataFields: DbtDataField[] = [];
    for (const selectedTable of reportParams.dbTables) {
      const dbtTable = tables?.find((table) => table.name === selectedTable.name);
      if (dbtTable) {
        let _dataFields = dbtTable.dataFields;

        if (!includeAllFields) {
          _dataFields = _dataFields.filter((dataField) => selectedTable.fields.includes(dataField.dataField));
        }

        _dataFields = _dataFields.map((dataField) => ({
          ...dataField,
          tableName: dbtTable.name,
          tableDisplayName: dbtTable.displayName,
        }));

        dataFields = dataFields.concat(_dataFields);
      }
    }

    return dataFields;
  };

  const toValidFilters = (filters: DbtFilter[][] | undefined): DbtFilter[][] | undefined => {
    if (!filters) {
      return undefined;
    }

    const validFilters = filters.map((filterGroup) =>
      filterGroup.filter(
        (filter) =>
          filter.active &&
          isDbtFilterValid(
            filter,
            tables?.filter((x) => reportParams?.dbTables?.map((y) => y.name).includes(x.name))
          )
      )
    );

    return validFilters.some((filterGroup) => filterGroup.length > 0) ? validFilters : undefined;
  };

  const canRunReport =
    reportParams.dbTables.length > 0 && JSON.stringify(reportParams) !== JSON.stringify(originalReportParams);

  return (
    <Page
      nav={
        <DashboardPageNav
          open={navOpen}
          tablesLoading={tablesLoading}
          tables={tables}
          selectedTables={reportParams.dbTables}
          setSelectedTables={(selectedTables: ReportParamsDbTable[]) =>
            setReportParams(
              produce((draft) => {
                draft.dbTables = selectedTables;
              })
            )
          }
        />
      }
      header={
        <PageHeader
          title={
            <Stack direction="row" alignItems={"center"} spacing="sm">
              <IconButton onClick={() => setNavOpen(!navOpen)}>{navOpen ? <MenuOpenIcon /> : <MenuIcon />}</IconButton>
            </Stack>
          }
          rightHeader={
            <>
              <FilterButton
                onClick={() => setFiltersOpen(true)}
                filtersCount={toValidFilters(reportParams.filters)?.reduce((sum, a) => sum + a.length, 0)}
              />

              {filtersOpen && (
                <DashboardPageFilters
                  tables={tables?.filter((x) => reportParams.dbTables.map((y) => y.name).includes(x.name))}
                  filters={reportParams.filters}
                  onApply={(filters) => {
                    const _reportParams = produce(reportParams, (draft) => {
                      draft.filters = filters;
                    });

                    setReportParams(_reportParams);
                    getGridData(_reportParams);
                  }}
                  onClose={() => setFiltersOpen(false)}
                />
              )}

              {/* <ToggleButtonGroup
                exclusive
                value={reportParams.gridType}
                onChange={(e, value) =>
                  setReportParams(
                    produce((draft) => {
                      draft.gridType = value;
                    })
                  )
                }
                size="small"
              >
                {gridTypes.map((x) => (
                  <ToggleButton value={x}>{x}</ToggleButton>
                ))}
              </ToggleButtonGroup> */}

              <Button
                variant="contained"
                onClick={() => {
                  getGridData(reportParams);
                  setNavOpen(false);
                }}
                disabled={!canRunReport}
              >
                Run
              </Button>
            </>
          }
        />
      }
    >
      <DashboardPageDataGrid
        dataFields={getSelectedTableFields()}
        pageSort={reportParams.pageSort}
        setPageSort={(pageSort: PageSort) => {
          const _reportParams = produce(reportParams, (draft) => {
            draft.pageSort = pageSort;
          });

          setReportParams(_reportParams);
          getGridData(_reportParams);
        }}
        generatedReport={generatedReport}
      />
    </Page>
  );
};
