import {
  Box,
  CancelIconButton,
  Checkbox,
  DatePicker,
  DbtDataFieldTypeIcon,
  Grid,
  Input,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "components";
import produce from "immer";
import {
  DbtDataField,
  DbtFilter,
  DbtFilterOperator,
  DbtFilterOperatorOption,
  dbtFilterOperatorOptions,
  DbtTable,
  isDbtFilterValid,
} from "models";

type DashboardFilterProps = {
  tables: DbtTable[] | undefined;
  filter: DbtFilter;
  setFilter: (filter: DbtFilter) => void;
  removeFilter: () => void;
};

export const DashboardPageFilter = ({ tables, filter, setFilter, removeFilter }: DashboardFilterProps) => {
  const selectedTable = tables?.find((x) => x.name === filter.table);
  const selectedField = selectedTable?.dataFields?.find((x) => x.dataField === filter.field);

  return (
    <Box width="100%">
      <Grid container spacing="sm" width="100%">
        <Grid item display="inline-flex">
          {isDbtFilterValid(filter, tables) ? (
            <Tooltip
              title={
                filter.active
                  ? "Check off to unapply this filter to the query."
                  : "Check on to apply this filter to the query."
              }
            >
              <Checkbox
                checked={filter.active}
                onChange={(e) =>
                  setFilter(
                    produce(filter, (draft) => {
                      draft.active = e.target.checked;
                    })
                  )
                }
              />
            </Tooltip>
          ) : (
            <Tooltip title="This filter is not valid and cannot be applied to the query">
              <Checkbox disabled />
            </Tooltip>
          )}
        </Grid>
        <Grid item flexGrow={1} flexBasis={0}>
          <Select
            label="Table"
            value={tables?.find((x) => x.name === filter.table)}
            options={tables}
            getOptionLabel={(option: DbtTable) => option.displayName!}
            onChange={(e, value: DbtTable) =>
              setFilter(
                produce(filter, (draft) => {
                  draft.table = value ? value.name : undefined;
                })
              )
            }
            size="small"
            disableClearable
          />
        </Grid>
        <Grid item flexGrow={1} flexBasis={0}>
          <Select
            label="Field"
            value={selectedTable?.dataFields?.find((x) => x.dataField === filter.field)}
            options={selectedTable?.dataFields}
            getOptionLabel={(option: DbtDataField) => option.displayName!}
            renderOption={(props, option: DbtDataField) => (
              <Box component="li" {...props}>
                <Stack width="100%" direction="row" spacing="sm" alignItems={"center"}>
                  <DbtDataFieldTypeIcon type={option.dataType} />
                  <Typography variant="inherit">{option.displayName}</Typography>
                </Stack>
              </Box>
            )}
            onChange={(e, value: DbtDataField) =>
              setFilter(
                produce(filter, (draft) => {
                  draft.field = value ? value.dataField : undefined;
                })
              )
            }
            size="small"
            noOptionsText={!selectedTable && "No selected table"}
            disableClearable
          />
        </Grid>
        <Grid item flexGrow={1} flexBasis={0}>
          <Select
            label="Operator"
            value={dbtFilterOperatorOptions?.find((x) => x.value === filter.operator)}
            options={dbtFilterOperatorOptions?.filter(
              (x) => selectedField && x.applicableFieldTypes.includes(selectedField.dataType)
            )}
            getOptionLabel={(option: DbtFilterOperatorOption) => option.text!}
            renderOption={(props, option: DbtFilterOperatorOption) => (
              <Box component="li" {...props}>
                <Stack direction="column">
                  <Typography variant="inherit">{option.text}</Typography>
                  <Typography variant="caption">{option.description}</Typography>
                </Stack>
                {/* {option.text} {option.description && <>({option.description})</>} */}
              </Box>
            )}
            onChange={(e, value) =>
              setFilter(
                produce(filter, (draft) => {
                  draft.operator = value.value as unknown as DbtFilterOperator;
                })
              )
            }
            size="small"
            noOptionsText={!selectedField && "No selected field"}
            disableClearable
          />
        </Grid>
        <Grid item flexGrow={1} flexBasis={0}>
          {selectedField?.dataType === "datetime" || selectedField?.dataType === "date" ? (
            <DatePicker
              label="Value"
              value={filter.value}
              onChange={(value) =>
                setFilter(
                  produce(filter, (draft) => {
                    draft.value = value;
                  })
                )
              }
              size="small"
            />
          ) : (
            <Input
              label="Value"
              value={filter.value}
              onChange={(e) =>
                setFilter(
                  produce(filter, (draft) => {
                    draft.value = e.target.value;
                  })
                )
              }
              size="small"
            />
          )}
        </Grid>
        <Grid item display="inline-flex" alignItems={"center"}>
          <Stack direction="row" spacing="sm" alignItems={"center"}>
            <CancelIconButton onClick={() => removeFilter()} />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
