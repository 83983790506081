import { ElementType, lazy, Suspense } from "react";

const Loadable = (Component: ElementType) => (props: any) => {
  return (
    <Suspense fallback={null}>
      <Component {...props} />
    </Suspense>
  );
};

export const lazyLoadingComponents = () => {
  const LoginPage = Loadable(
    lazy(() =>
      import("pages/LoginPage/LoginPage").then((module) => ({
        default: module.LoginPage,
      }))
    )
  );

  const DashboardPage = Loadable(
    lazy(() =>
      import("pages/DashboardPage/DashboardPage").then((module) => ({
        default: module.DashboardPage,
      }))
    )
  );

  return {
    LoginPage,
    DashboardPage,
  };
};
