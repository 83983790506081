import { createTheme } from "@mui/material/styles";
import { PaletteOptions } from "@mui/material/styles/createPalette"; // need this
import { Table, Grid, Paper, Card, Drawer } from "./overrideComponents";
import { overrideComponents } from "./overrideComponents/components";

// A custom theme for this app
export let theme = createTheme({
  spacing: 6, // 8 is default
  palette: {
    primary: {
      main: "rgb(13, 98, 141)", // logo color: #0d628d
      light: "#1e78df",
      dark: "#0b4a6a",
    },
    // secondary: {
    //   main: '#19857b',
    // },
    // error: {
    //   main: red.A400,
    // },
    background: {
      default: "rgb(242, 245, 249)",
      //neutral: "#fdfdfd",
      //hover: 'rgba(13, 98, 141, 0.04)'
    },
    border: {
      main: "rgba(0, 0, 0, 0.12)",
      light: "whitesmoke",
      dark: "black",
    },
  },
  zIndex: {},
});

declare module "@mui/material/styles" {
  interface Palette {
    border: {
      main: string;
      light: string;
      dark: string;
    };
  }
  interface PaletteOptions {
    border: {
      main: string;
      light: string;
      dark: string;
    };
  }
  // interface TypeBackground {
  //   neutral: string;
  // }
}

theme.components = overrideComponents(theme);
