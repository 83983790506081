import { DbtDataFieldType, DbtTable } from "models";

export type DbtFilter = {
  table?: string;
  field?: string;
  operator?: DbtFilterOperator;
  value?: string;
  valueOptions?: string[];
  active?: boolean;
};

export type DbtFilterProperty = { name: string };
export type DbtFilterOperator = "=" | ">" | ">=" | "<" | "<=" | "contains" | "begins with" | "ends with";

export type DbtFilterOperatorOption = {
  value: DbtFilterOperator;
  text: string;
  description?: string;
  applicableFieldTypes: DbtDataFieldType[];
};

export const dbtFilterOperatorOptions: DbtFilterOperatorOption[] = [
  {
    value: "=",
    text: "=",
    description: "equals",
    applicableFieldTypes: ["string", "number", "boolean", "date", "datetime"],
  },
  {
    value: ">",
    text: ">",
    description: "greater than",
    applicableFieldTypes: ["number", "date", "datetime"],
  },
  {
    value: ">=",
    text: ">=",
    description: "greater than or equals",
    applicableFieldTypes: ["number", "date", "datetime"],
  },
  {
    value: "<",
    text: "<",
    description: "smaller than",
    applicableFieldTypes: ["number", "date", "datetime"],
  },
  {
    value: "<=",
    text: "<=",
    description: "smaller than or equals",
    applicableFieldTypes: ["number", "date", "datetime"],
  },
  {
    value: "contains",
    text: "contains",
    applicableFieldTypes: ["string"],
  },
  {
    value: "begins with",
    text: "begins with",
    applicableFieldTypes: ["string"],
  },
  {
    value: "ends with",
    text: "ends with",
    applicableFieldTypes: ["string"],
  },
];

export const isDbtFilterValid = (filter: DbtFilter, tables: DbtTable[] | undefined): boolean => {
  // all fields need to have values
  if (!filter.table || !filter.field || !filter.operator || !filter.value) {
    return false;
  }

  const selectedTable = tables?.find((x) => x.name === filter.table);

  // field needs to belong to correct table
  if (!selectedTable?.dataFields?.map((x) => x.dataField).includes(filter.field)) return false;

  // Find the DbtFilterOperatorOption for the given operator
  const operatorOption = dbtFilterOperatorOptions.find((option) => option.value === filter.operator);

  // If the operator is not found in the dbtFilterOperatorOptions array, return false
  if (!operatorOption) {
    return false;
  }

  // Find the field's data type
  const fieldType: DbtDataFieldType | undefined = selectedTable?.dataFields?.find(
    (x) => x.dataField === filter.field
  )?.dataType;

  // If the field's data type is not found, return false
  if (!fieldType) {
    return false;
  }

  // Check if the operator's applicableFieldTypes include the field's data type
  return operatorOption.applicableFieldTypes.includes(fieldType);

  return true; // remove later
};
